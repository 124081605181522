<template>
	<div>
		<section
			class="hero-banner-wrapper"
			ref="heroBanner"
		>
			<component
				:is="wrapper"
				v-bind="content.clickableBanner ? firstLink : ''"
				custom-type="div"
			>
				<div
					role="img"
					class="hero-banner"
					:class="{
						'parallax-container': content.parallaxHero || isMobile,
						'dark-mode': dark,
						isTransparent,
					}"
				>
					<ImageResponsive
						v-if="image"
						v-bind="image"
						:dark="dark"
						class="image"
						eager
						auto-aspect
					/>

					<Transition
						v-if="content.heroTitle"
						name="slide-in"
					>
						<div
							ref="contentParent"
							:class="contentParentClasses.concat('padding-l')"
						>
							<TnCard
								v-if="
									content.heroTitle ||
									(heroTexts.content && heroTexts.content.length) ||
									heroIcon ||
									anchorLink ||
									(links && links.length)
								"
								:paddingX="heroCardPadding"
								:paddingY="heroCardPadding"
								:class="cardClasses"
							>
								<TnBadge
									v-if="showNewsBadge"
									:size="$store.getters.isMobile ? 'xs' : 'm'"
									class="hero-banner-badge"
								>
									{{ badgeText }}
								</TnBadge>
								<h1
									v-if="content.heroTitle"
									class="hero-banner__heading"
									v-editable-string
								>
									{{ content.heroTitle }}
								</h1>
								<div
									v-if="heroIcon"
									class="hero-icon margin-vertical-l"
								>
									<img
										:src="heroIcon.src"
										:alt="heroIcon.alt"
										data-aspect="0"
									/>
								</div>
								<div
									v-if="heroTexts.content && heroTexts.content.length"
									class="hero-text"
									:class="{ 'alternating-texts': heroTexts.content.length > 1 }"
								>
									<TransitionGroup :name="heroTexts.transitionType">
										<template v-for="content in heroTexts.content">
											<div
												:key="`herotext-${content.id}`"
												ref="heroTexts"
												class="text-normal margin-top-xs"
												v-if="showingIndex === content.id"
											>
												{{ content.text }}
											</div>
										</template>
									</TransitionGroup>
								</div>

								<div
									v-if="anchorLink || links.length > 0"
									class="links-container"
								>
									<AnchorLink
										v-bind="anchorLink"
										:button="displayAsButton"
										size="s"
										v-if="anchorLink"
										:block="false"
										:secondary="secondaryButton"
										chevronRight
										chevronAnimated
										:dark="dark"
										class="margin-top-m"
									></AnchorLink>
									<div
										v-for="(link, index) in links"
										:key="index"
									>
										<TnButton
											v-if="displayAsButton && link.text"
											:to="link.to || link.href"
											:text="link.text"
											:secondary="!!secondaryButton"
											:dark="dark"
											class="margin-right-l margin-top-l"
										/>
										<LinkInline
											v-else-if="!displayAsButton && link.text"
											v-bind="link"
											:chevronRight="link.chevronRight"
											:openInNewWindow="link.openInNewWindow"
											class="margin-right-l margin-top-l link-text"
										/>
									</div>
								</div>
							</TnCard>
						</div>
					</Transition>
					<div
						v-if="dark"
						class="gradient"
					></div>
				</div>
			</component>
		</section>
	</div>
</template>

<script>
import ResponsiveImageViewModel from "../../../../helpers/ViewModels/ResponsiveImageViewModel.js";
import LinkViewModel from "../../../../helpers/ViewModels/LinkViewModel.js";
import AnchorViewModel from "../../../../helpers/ViewModels/AnchorViewModel.js";

import { spacingXl } from "@/assets/tokens/js/es6.js";

export default defineNuxtComponent({
	name: "HeroBanner",

  props: {
    component: {
      type: Object,
      required: true
    },
    content: {
      type: Object,
      required: true,
      default: () => ({})
    },
    dark: {
      type: Boolean,
      default: false
    }
  },

	data() {
		return {
			showingIndex: 0,
			heroTextHeight: 50,
		};
	},

  setup(props){
    const backgroundColor = useBackgroundColor(props.component.content.backgroundColor);
    return {
      backgroundColor
    }
  },

	computed: {
		wrapper() {
			if (this.content.clickableBanner) return resolveComponent("LinkWrap");
			return "div";
		},
		badgeText() {
			return this.content?.newsBadge?.badgeText || "Nyhet!";
		},
		showNewsBadge() {
			return new Date().getTime() <= new Date(this.content?.newsBadge?.newsBadgeEndDate).getTime();
		},
		heroCardPadding() {
			return spacingXl;
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		headingSize() {
			if (!this.content.smallTitle && this.backgroundColor === "transparent") return null;
			return (this.content.smallTitle && "lg") || "xl";
		},
		heroIcon() {
			return this.content.heroIcon;
		},
		displayAsButton() {
			return this.content.heroLinkButton;
		},
		secondaryButton() {
			return this.content.secondaryButton;
		},
		contentPosition() {
			return this.content.contentPosition && this.content.contentPosition.toLowerCase();
		},
		contentVerticalPosition() {
			return this.content.contentVerticalPosition;
		},
		image() {
			return ResponsiveImageViewModel(this.component.content.heroResponsiveImage);
		},
		color() {
			return this.content.backgroundColor;
		},
		anchorLink() {
			return this.component.content.heroInternalAnchor
				? AnchorViewModel(this.component.content.heroInternalAnchor)
				: null;
		},
		contentParentClasses() {
			const verticalPosition = this.contentVerticalPosition;
			const classes = ["content-parent"];
			if (verticalPosition) classes.push(`hero-banner-${verticalPosition}`);
			if (this.isTransparent) classes.push("stack-on-mobile");
			if (this.dark) classes.push("dark");
			return classes;
		},
		isTransparent() {
			return this.color === "transparent";
		},
		cardClasses() {
			const classes = ["card", "content"];
			if (this.contentPosition) classes.push(this.contentPosition.toLowerCase());
			else classes.push("center");
			if (this.component.content.backgroundColor) classes.push(this.backgroundColor);

			return classes;
		},
		links() {
			const links = this.content.links || [];
			return links.map((link) => link && LinkViewModel(link)).filter((link) => link);
		},
		firstLink() {
			return this.links && this.links.length > 0 && this.links[0];
		},
		isNoLinkVisible() {
			return (
				this.links &&
				this.links.length > 0 &&
				this.links.filter((link) => !!(link.text && link.text.trim())).length === 0
			);
		},
		heroTexts() {
			const heroTexts = { content: [], transitionType: "", transitionInterval: 0 };
			const heroTextsArray = this.content.heroTexts;
			heroTexts.transitionType = this.content.heroTextsTransitionType || "fade";
			heroTexts.transitionInterval = this.content.heroTextsInterval || "6";
			if (heroTextsArray) {
				let id = 0;
				heroTextsArray.forEach((text) => heroTexts.content.push({ text, id: id++ }));
			}
			return heroTexts;
		},
	},

	methods: {
		scrollBanner() {
			// Handles animating the hero banner image and the contents while scrolling.
			const heroBanner = this.$refs.heroBanner;
			if (heroBanner) {
				const bannerHeight = heroBanner.offsetHeight;
				const bannerOffset = heroBanner.getBoundingClientRect().top + document.body.scrollTop;
				const picture = heroBanner.getElementsByClassName("responsive-image")[0];
				const scrollTop = heroBanner.scrollTop;
				const diff = scrollTop - bannerOffset;
				const elementScale = this.getPercentageMoved(bannerHeight, diff);
				const card = heroBanner.getElementsByClassName("content")[0];

				if (scrollTop > bannerOffset) {
					const pictureDisplacement = Math.abs((bannerHeight * elementScale) / 2).toFixed(0);
					let contentDisplacement = Math.round(pictureDisplacement / 4);
					contentDisplacement = contentDisplacement > 24 ? 24 : contentDisplacement;
					picture.style.display = "block";
					picture.style.transform = `translateY(${pictureDisplacement}px)`;
					if (this.isMobile && card) card.style.transform = `translateY(-${contentDisplacement}px)`;
				} else {
					picture.style.display = "initial";
					picture.style.transform = `translateX(0) translateY(0)`;
					if (this.isMobile && card) card.style.transform = `translateY(0)`;
				}
			}
		},
		getPercentageMoved(bannerHeight, diff) {
			// Finds the fraction of the total banner that has been scrolled at present.
			const calculation = diff / bannerHeight;
			if (calculation > 0 && calculation <= 1) return calculation;
			return calculation > 1 ? 1 : 0;
		},
	},

	mounted() {
		const heroTexts = this.heroTexts;
		if (heroTexts.content.length > 1) {
			setInterval(() => {
				if (this.showingIndex < heroTexts.content.length - 1) {
					this.showingIndex++;
				} else this.showingIndex = 0;
			}, heroTexts.transitionInterval * 1000);
		}
		if (this.content.parallaxHero || this.isMobile) {
			if (this.isMobile && (!this.color || this.color === "transparent")) return;

			window.addEventListener("scroll", this.scrollBanner);
		}
	},

	beforeUnmount() {
		if (this.content.parallaxHero || this.isMobile) window.removeEventListener("scroll", this.scrollBanner);
	},
});
</script>

<style lang="scss">
.hero-banner-wrapper {
	.link-wrap {
		display: block;
	}

	.hero-banner {
		position: relative;

		&__heading {
			color: $color-neutrals-black;

			@include font-title-xl;

			@include breakpoint(mobile) {
				@include font-title-bold-m;
			}
		}

		.hero-banner-badge {
			position: absolute !important;
			top: 0;
			left: $spacing-xl;
		}

		&.dark-mode {
			.gradient {
				display: block;
				position: absolute;
				inset: 0;
				background: linear-gradient(-180deg, transparent, $color-twe-background 100%);
			}

			.hero-banner__heading {
				color: $color-twe-text;
			}

			.content-parent {
				.content.card,
				.content.card.dynamic {
					&.transparent.center {
						width: 100%;
					}
				}
			}
		}

		:deep(img.responsive-image) {
			min-width: 100%;
			width: 100%;
			object-fit: cover;
			object-position: 50% 0;
		}

		.content-parent {
			max-width: $size-screen-desktop;
			position: absolute;
			left: 50%;
			bottom: 50%;
			transform: translate(-50%, 50%);
			width: 100%;
			z-index: 1;
			transition: all 0.25s ease-out;

			&.stack-on-mobile {
				&.dark {
					.content.transparent {
						color: $color-twe-text;

						.text-normal {
							color: $color-twe-text;
						}
					}
				}

				@include breakpoint(mobile) {
					position: relative;
					margin: 0 auto;
					padding: $spacing-m;

					.content.transparent {
						color: $color-neutrals-black;
					}
				}
			}

			&.hero-banner-top {
				top: $spacing-xl;
				transform: translateX(-50%);

				@include breakpoint(mobile) {
					top: auto;
					transform: translate(-50%, 0%);
				}
			}

			&.hero-banner-bottom {
				bottom: $spacing-xl;
				transform: translateX(-50%);

				@include breakpoint(tablet) {
					bottom: 0;
				}
			}

			.content.card,
			.content.card.dynamic {
				width: 45%;

				&.center {
					display: block;
					margin: 0 auto;
				}

				&.right {
					float: right;
					text-align: left;
				}

				&.transparent {
					background-color: transparent !important;
					color: $color-twe-text;

					.text-normal {
						@include font-text-xl;
					}

					.links-container {
						justify-content: center;

						.link-text {
							font-size: 20px;
						}
					}
				}

				&.transparent.center {
					width: 60%;
					text-align: center;

					@include breakpoint(tablet) {
						width: 70%;
					}

					@include breakpoint(mobile) {
						width: 100%;
					}
				}

				.hero-icon {
					height: 40px;
					display: block;
					text-align: center;

					img {
						height: 100%;
					}
				}

				.hero-text {
					position: relative;
				}

				.text-normal {
					color: $color-neutrals-600-shade;
					width: 100%;

					@include font-text-xl;

					@include breakpoint(mobile) {
						@include font-text-l;
					}
				}

				.alternating-texts {
					&.hero-text {
						min-height: 60px;
					}

					.text-normal {
						position: absolute;

						@include font-text-m;
					}
				}

				.links-container {
					display: flex;
					flex-wrap: nowrap;
					align-items: center;
					width: 100%;
				}

				@include breakpoint(tablet) {
					width: 70%;
				}

				@include breakpoint(mobile) {
					margin: 0 auto;
					width: 100%;
					bottom: 0;
				}
			}

			@include breakpoint(mobile) {
				bottom: 0;
				transform: translate(-50%, 0%);
			}
		}

		&.parallax-container {
			width: 100%;
			position: relative;
			overflow: hidden;

			:deep(img.responsive-image) {
				object-fit: cover;
				object-position: 50% 0;

				@include breakpoint(mobile) {
					max-height: 450px;
				}
			}
		}
	}

	.padding-top-3xl .hero-banner {
		margin-top: -64px;
	}
}
</style>

<style lang="scss" scoped>
// Transitions
.content-parent.slide-in-enter-from {
	position: absolute;
	left: 50%;
	bottom: 50%;
	transform: translate(-50%, 200%);
}

.content-parent.slide-in-enter-to {
	transform: translate(-50%, 50%);
}

.content-parent.slide-in-enter-active {
	transition: all 0.5s ease-in;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-active {
	opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
	transition: all 0.5s ease;
}

.slide-enter-active {
	transition-delay: 0.5s;
}

.slide-enter-from {
	transform: translateX(20%);
	opacity: 0;
}

.slide-enter-to {
	opacity: 1;
}

.slide-leave-to {
	transform: translateX(-10%);
	opacity: 0;
}

.card {
	font-family: $font-family-telenor-ui;
	height: auto;
	padding: $spacing-xl;
	display: inline-block;
	box-sizing: border-box;
	border-radius: 0 !important;
	position: relative;
	flex-direction: column;
	text-align: left;

	&.float-right {
		float: right;
	}

	&.link {
		text-decoration: inherit;
		color: inherit;
		cursor: pointer;
	}

	/* Sizes - xs-xl */
	&.xs {
		width: 2 * $spacing-3xl;
	}

	&.sm {
		width: 3 * $spacing-3xl;
	}

	&.md {
		width: 4 * $spacing-3xl;
	}

	&.lg {
		width: 6 * $spacing-3xl;
	}

	&.xl {
		width: 8 * $spacing-3xl;
	}
}
</style>
